import type { FC } from 'react';
import styled from '@emotion/styled';

type FlexProps = {
  title?: number;
  description?: number;
  form?: number;
};

type ContactContainerProps = {
  children: any;
  title: string | React.ReactNode;
  desc: string | React.ReactNode;
  decorator?: boolean;
  style?: React.CSSProperties;
  flex?: FlexProps;
  isSubmitSuccessful?: boolean;
};

export const ContactContainer: FC<ContactContainerProps> = ({
  children,
  title,
  desc,
  decorator = false,
  style,
  flex = {
    title: 100,
    description: 50,
    form: 50,
  },
}) => {
  return (
    <Container>
      {decorator && <Decorator />}
      <TripletContainer style={style}>
        <TitleContainer style={{ flex: `${flex.title}%` }}>
          {title}
        </TitleContainer>
        <LeftColumnContainer style={{ flex: `${flex.description}%` }}>
          {desc}
        </LeftColumnContainer>
        <RightColumnContainer style={{ flex: `${flex.form}%` }}>
          {children}
        </RightColumnContainer>
      </TripletContainer>
    </Container>
  );
};

export const Container = styled.div`
  padding: var(--navbar-height) 3.125rem 1px 4.5rem;
  box-sizing: border-box;
  height: auto;
  color: white;
`;

export const Decorator = styled.div`
  margin: calc(
      max(var(--navbar-height), 239px) - min(var(--navbar-height), 239px)
    )
    auto 135px auto;
  height: 0;
  width: 100%;
  border: 1px solid white;
`;

export const TripletContainer = styled.div`
  max-width: 1360px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    flex: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  flex: 100%;
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftColumnContainer = styled.div`
  padding: 0 0 0 0;
  flex: 48%;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
`;

export const RightColumnContainer = styled.div`
  padding: 35px 0 0 0;
  flex: 50%;
  font-weight: 600;

  @media screen and (max-width: 1024px) {
    flex: 100%;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
`;

export const ColumnContainer = styled.div`
  flex: 1;
  flex-basis: 100%;
  @media screen and (min-width: 1200px) {
    flex-basis: 50%;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 4.5rem;
  margin: 0;
  grid-area: 1 / 1 / 2 / 6;
  ${({ isOutlined }: { isOutlined?: boolean }) =>
    isOutlined && {
      WebkitTextFillColor: 'transparent',
      WebkitTextStroke: 1,
    }}

  @media screen and (max-width: 1200px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.3rem;
  }
`;

export const Body = styled.p`
  font-size: 1rem;
  max-width: 448px;
`;

export const NewInquiryBtn = styled.button`
  padding: 16px 32px;
  margin-top: 32px;
  width: fit-content;
  color: white;
  border: 2px solid white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background: transparent;

  &:hover {
    background-color: var(--primary);
    color: black;
    cursor: pointer;
  }
`;
