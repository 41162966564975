import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { CategoryGroups } from './data';
import { Header } from './Header';
import { Info } from './Info';

interface TabsProps {
  data: CategoryGroups;
}

type AnimationValues = { left: number, width: number}

export const Tabs = ({ data }: TabsProps) => {
  const firstMount = useRef(true);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedService, setSelectedService] = useState(0);
  const [animationValues, setAnimationValues] = useState<AnimationValues| null>(null);

  const calculateAnimationValues = (element: HTMLButtonElement) => {
    let animationValues = {} as AnimationValues;
    let difference = 0;
    const parent = element.parentElement;
    if (parent) {
      const parentRect = parent.getBoundingClientRect();
      const elRect = element.getBoundingClientRect();
      const style = window.getComputedStyle(element);
      // const paddingLeft = parseFloat(style.paddingLeft);
      // const paddingRight = parseFloat(style.paddingRight);
      // Adjust left and width to correct the positioning
      const left = elRect.left - parentRect.left; // Adjusted to be relative to parent
      const width = elRect.width; // Include padding left and right

      return { left, width }
    }

    return animationValues;
  };

  const handleSelectedTab = useCallback((e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    const newAnimationValues = calculateAnimationValues(e.target as HTMLButtonElement);
    setActiveTab(index);
    setAnimationValues(newAnimationValues);
    setSelectedService(0);
  }, []);

  const handleSelectedService = useCallback((index: number) => {
    setSelectedService(index);
  }, []);


  const underlineVariants = {
    initial: { left: 0, width: '182px' },
    animate: { x: animationValues?.left, width: animationValues?.width },
  };

  return (
    <TabContainer>
      <TabHeaders>
        {Object.entries(data).map(([key, _], index) => (
          <TabButton
            key={`tabs-${index}`}
            data-tabs={`tabs-${index}`}
            isActive={activeTab === index}
            onClick={(e) => handleSelectedTab(e, index)}
          >
            {key}
          </TabButton>
        ))}
        <Underline
          initial="initial"
          animate="animate"
          variants={underlineVariants}
          transition={{ duration: 0.2 }}
        />
      </TabHeaders>
      <TabContents>
        {Object.entries(data).map(([_, value], index) => (
          <TabContent
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: activeTab === index ? 1 : 0 }}
            isActive={activeTab === index}
          >
            <Header services={value} onClick={handleSelectedService} selected={selectedService} />
            <Info
              title={value[selectedService]?.title}
              content={value[selectedService]?.content}
              keywords={value[selectedService]?.services}
              codes={value[selectedService]?.codes}
            />
          </TabContent>
        ))}
      </TabContents>
    </TabContainer>
  );
};

const Underline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease-in-out;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
`;

const TabHeaders = styled.div`
  display: flex;
  position: relative;
  gap: 40px;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 28px;
  font-family: Poppins;
  font-weight: 200;
  &.active {
    font-weight: bold;
  }
`;

const TabContents = styled.div``;

const TabContent = styled(motion.div)<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  gap: 6rem;
`;