import React, { useState } from "react";
import styled from "@emotion/styled";

export interface OptionProp {
  value: string;
  label: string;
}

interface SelectProps {
  label?: string;
  options: OptionProp[];
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  error?: boolean;
}

const Select = ({ label, options, value, onChange, required }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SelectContainer>
      <SelectLabel htmlFor={label}>{label}</SelectLabel>
      <SelectInput
        id={label}
        className={`select__input ${isOpen ? "open" : ""}`}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        required={required}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            style={{ background: isOpen && value === option.value ? "#011627" : "#D9D9D9", color: isOpen && value === option.value ? "#fff" : "#000", cursor: "pointer" }}
          >
            {option.label}
          </Option>
        ))}
      </SelectInput>
      <SelectIcon className="select-icon" onClick={handleClick}>
        {!isOpen ? (
          <img src="/chevron-down.svg" alt="chev-down" />
        ) : (
          <img src="/chevron-up.svg" alt="chev-up" />
        )}
      </SelectIcon>
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  background: none;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #FFFFFF;
  color: white;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0;
  width: 100%;
  outline: none;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const SelectLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 300;
  color: white;
`;

const SelectInput = styled.select`
  appearance: none;
  background: none;
  border: none;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 1.125rem;
  font-weight: 300;
  width: 100%;
  outline: none;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;

  & option:hover {
    background: var(--secondary);
    color: #fff;
  }

  &:focus {
    border-bottom: 1px solid #add8e6;
  }

  &::-ms-expand {
    display: none;
  }
`;

const Option: any = styled.option`
  cursor: pointer;
  padding: 0.3rem;
}`;

const SelectIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
`;

export default Select;
