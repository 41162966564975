import styled from '@emotion/styled';

export const StyledRemixForm: any = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 42px;
`;

export const InputGroup: any = styled.div`
  display: flex;
  gap: ${(props: { gap?: string }) => props.gap ?? '38px'};
  flex-basis: 100%;
`;

export type InputProps = {
  error?: boolean;
  flexBasis?: string;
};

export const Input: any = styled.input`
  background: none;
  border: ${({ error }: InputProps) => (error ? '1px solid #cc0000' : 'none')};
  font-family: 'Poppins', sans-serif;
  border-bottom: 1.2px solid white;
  color: white;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0 0 9.48px 0;
  flex-basis: ${({ flexBasis }: InputProps) => flexBasis ?? '100%'};
  width: ${({ flexBasis }: InputProps) => flexBasis ?? '100%'};

  :focus-visible {
    outline: none;
  }

  ::-ms-input-placeholder {
    color: white;

  &::placeholder {
    color: #fff;

  }
`;

export const FormButtonGroup: any = styled.div`
  display: flex;
  justify-content: ${({
    justifyContent = 'flex-start',
  }: {
    justifyContent?: string;
  }) => justifyContent};
`

export const Select: any = styled.select`
  background: none;
  border: ${({ error }: InputProps) => error ? '1px solid #cc0000' : 'none'};
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid white;
  color: white;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0;
  width: 100%;
  outline: none;
`;

export const Role = styled.div`
  cursor: pointer;
  &.active {
    color: #fff;
  }
  &.disabled {
    color: #292F34;
  }
`;

export const Carousel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const CarouselButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: #888;
  margin-left: 1rem;
  transform: translateX(0);
  transition: transform ease-out 0.2s;
  &:hover {
    transform: translateX(5px);
    transition: transform ease-in 0.2s;
  }
  &:focus-visible {
    outline: none;
  }
`;

export const CarouselTrack = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const CarouselItem = styled.div`
  flex: 0 0 auto;
  white-space: nowrap;
  font-size: 4.5rem;
  font-weight: bold;
  transition: transform 0.3s ease-out;
  min-width: 326px;
  &.carousel-item:not(.active) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    font-size: 3rem;
    min-width: 217px;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
    min-width: 144.6px;
  }
`;
