import styled from '@emotion/styled';
import { Title, SubHeader } from './styles';

const Intro = () => {
  return (
    <IntroContainer>
      <Title className="project-title">We Are Let's Code It</Title>
      <SubHeader>A digital agency focused on innovative marketing and products, with engineering and design at our core. We craft engaging digital experiences and softwares that solve problems across a myriad of industries.</SubHeader>
    </IntroContainer>
  );
};

const IntroContainer = styled.div`
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  max-width: 800px;
  margin: 160px auto;
`;

export default Intro;
