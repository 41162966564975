import { useState, useEffect } from 'react';

export function useGetProjectsData() {
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);

  const fetchProjects = async () => {
    setProjectLoading(true);
    try {
      const res = await fetch(
        `${
          import.meta.env.VITE_STRAPI_ADDRESS
        }/api/projects?populate=fallbackBGImg,video`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_KEY}`,
          },
        }
      );
      const projects = await res.json();
      setProjects(projects.data);
    } catch (e) {}
    setProjectLoading(false);
  };

  useEffect(() => {
    if (projects.length === 0) {
      fetchProjects();
    }
  }, [projects]);

  return { projects, projectLoading };
}
