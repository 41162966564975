import React from 'react';
import { Link } from 'react-router-dom';
import ContractHolder from '../../assets/gsa/contract_holder_mark_black.png';
import GSAAdvantage from '../../assets/gsa/Reverse_GSA Advantage_only_2020.png';
import GSASchedule from '../../assets/gsa/schedule_mark_black.png';

import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FooterLink = styled.div`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const LocationText = styled.span`
  margin: 20px;
  font-size: 12px;
`;

const Figure = styled.figure<{ height?: number }>`
  height: ${({ height }) => height ?? 50}px;
  margin: 10px;
`

const VerticalLine = styled.div`
  display: none;
  @media (min-width: 768px) { 
    height: 50px;
    width: 1px;
    background-color: black;
  }
`

const Container = styled.div<{direction?: 'column' | 'row', gap?: number }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 20}px;
  font-size: 12px;
  align-items: center;

  @media (min-width: 768px) { 
    align-items: center;
    justify-content: center;
    flex-direction: ${({ direction }) => direction ?? 'row'};
  }
`

export const Footer = () => {
  return (
    <FooterContainer>
      <LocationText>
        Based in San Diego, CA
      </LocationText>
      <Container>
        <Container>
          <Container direction='column' gap={5}>
            <span>Contract #47QTCA24D009C</span>
            <Link target='_blank' to="/capabilities">GSA Capabilities</Link>
          </Container>
          <VerticalLine />
        </Container>
        <ImageContainer>
          <Figure height={42}>
            <img src={GSAAdvantage} alt="GSA Advantage"  height="100%" />
          </Figure>
          <Figure>
            <img src={GSASchedule} alt="GSA Schedule"  height="100%" />
          </Figure>
          <Figure>
            <img src={ContractHolder} alt="GSA Contract holder" height="100%" />
          </Figure>
        </ImageContainer>
      </Container>

    </FooterContainer>
  );
};






