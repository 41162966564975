import React, { useEffect, useState } from 'react';
import { useForm, FieldValues, UseFormReturn } from 'react-hook-form';
import TalentFormLayout from '../../components/form/TalentFormLayout';
import { InputGroup } from '../../components/form/styled';
import { FormDescription } from '../../components/FormDescription';
import JointTitle, { TitleInformation } from '../../components/JointTitle';
import { HollowText, Title } from './components';
import { getFormValues } from './form-extraction';
import ReactHelmet from 'react-helmet';
import { SEO } from '../../components/SEO';

export async function getRoles(): Promise<TitleInformation[]> {
  const url = import.meta.env.VITE_STRAPI_ADDRESS;
  if (!url) return [];
  const path = '/api/talents';
  const response = await fetch(`${url}${path}`);
  if (!response.ok) return [];
  const { data } = await response.json();
  const sortedData = data.sort((a: { id: number }, b: { id: number }) =>
    a.id > b.id ? 1 : a.id < b.id ? -1 : 0
  );

  return sortedData.map((d: any) => d.attributes);
}

function Talent() {
  //To:Do: Tried not to hardcode this and dynamically generate the number based on roles; but i couldn't. I will try later
  const fm1 = useForm();
  const fm2 = useForm();
  const fm3 = useForm();
  const [availableRoles, setAvailableRoles] = useState<TitleInformation[]>([]);
  const [chosenRoleIndex, setChosenRoleIndex] = useState<number>(-1);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [forms, setForms] = useState<
    Record<number, UseFormReturn<FieldValues, any>>
  >({
    [chosenRoleIndex + 1]: useForm(),
  });

  const currentIndex =
    chosenRoleIndex === -1 ? chosenRoleIndex + 1 : chosenRoleIndex;
  const form = forms[currentIndex];

  const { register, setValue } = form || {};
  const currentRole = availableRoles.at(chosenRoleIndex);

  useEffect(() => {
    register?.('role');
    setValue?.('role', currentRole?.position_name);
  }, [register, setValue, currentRole]);

  const fetchdata = async () => {
    try {
      const roles = await getRoles();
      if (!roles.length) {
        setLoaded(true);
        return;
      }
      setAvailableRoles(roles);
      setChosenRoleIndex(0);
    } catch {}
    setLoaded(true);
  };
  useEffect(() => {
    fetchdata().then().catch();
  }, []);

  useEffect(() => {
    if (availableRoles.length && chosenRoleIndex >= 0) {
      setForms((prevForms) => ({
        ...prevForms,
        [chosenRoleIndex]:
          chosenRoleIndex === 0 ? fm1 : chosenRoleIndex === 1 ? fm2 : fm3,
      }));
    }
  }, [chosenRoleIndex]);

  if (!form) return null;

  if (loaded && !availableRoles.length) {
    return (
      <>
        <SEO
          title="Let's Code It - Come work with us!"
          description="Whether you are an engineer, designer, project manager, we would love to work together to create awesome work!"
        />
        <TalentFormLayout
          title={
            <Title>
              There <HollowText>doesn't</HollowText> seem to be any available{' '}
              <HollowText>roles</HollowText> at this time.
            </Title>
          }
          desc={'We are sorry for the inconvenience.'}
          form={form}
          formChannel={'#work-for-us'}
          btnText={'Join The Team'}
          showSubmit={false}
          decorator
        ></TalentFormLayout>
      </>
    );
  }

  const formValues = currentRole?.form_schema;

  if (!formValues)
    return (
      <>
        <SEO
          title="Let's Code It - Come work with us!"
          description="Whether you are an engineer, designer, project manager, we would love to work together to create awesome work!"
        />
        <TalentFormLayout
          title={<JointTitle />}
          desc={''}
          form={form}
          formChannel={'#work-for-us'}
          btnText={'Join The Team'}
          decorator
          showSubmit={false}
        ></TalentFormLayout>
      </>
    );

  const sortedFormValues = Object.fromEntries(
    Object.entries<any>(formValues).sort(
      ([, valueA], [, valueB]) => valueA.id - valueB.id
    )
  );

  const formFields = getFormValues(sortedFormValues, form);

  const title = (
    <JointTitle
      roles={availableRoles}
      currentlySelected={chosenRoleIndex}
      setChosenIndex={setChosenRoleIndex}
    />
  );
  const description = <FormDescription role={currentRole} talentPage />;
  return (
    <>
      <SEO
        title="Let's Code It - Come work with us!"
        description="Whether you are an engineer, designer, project manager, we would love to work together to create awesome work!"
      />
      <TalentFormLayout
        title={title}
        desc={description}
        form={form}
        formChannel={'#work-for-us'}
        btnText={'Join The Team'}
        decorator
        style={{
          margin:
            'calc(max(var(--navbar-height), 190px) - min(var(--navbar-height), 190px)) auto 135px auto',
        }}
      >
        {formFields.map((inputGroup, index) => {
          return (
            <InputGroup key={index}>
              {inputGroup.components.map((inputField, index) => {
                return (
                  <React.Fragment key={index}>{inputField}</React.Fragment>
                );
              })}
            </InputGroup>
          );
        })}
      </TalentFormLayout>
    </>
  );
}

export default Talent;
