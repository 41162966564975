import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import { useInView } from 'react-intersection-observer';

export type ProjectSchema = {
  tags: string;
  createdAt: string;
  publishedAt: string;
  subtitle: string;
  title: string;
  updatedAt: string;
  video: any;
  order: number;
  visitLink: string;
  fallbackBGImg: any;
};

export const Project: FC<{ data: ProjectSchema }> = ({ data }) => {
  const { title, subtitle, tags, visitLink, video, fallbackBGImg } = data;
  const { ref, inView } = useInView({
    threshold: 0,
    fallbackInView: true,
    rootMargin: '50%',
  });
  const myRef = useRef(null);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      import('../utils/gsap').then(({ default: gsap }) => {
        gsap.from(myRef.current, {
          x: -100,
          autoAlpha: 0,
          duration: 0.6,
          scrollTrigger: {
            trigger: myRef.current,
            start: 'top center',
            toggleActions: 'play none none none',
          },
        });
      });
    }
  }, []);

  return (
    <section
      className="fullscreen-page project"
      ref={ref}
      role="structure"
      style={
        !video?.data?.attributes?.url && inView
          ? {
              backgroundImage: `url${fallbackBGImg?.data?.attributes?.url}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }
          : { background: 'black' }
      }
    >
      <div className="project-body" ref={myRef}>
        <h6 className="project-tags">{tags?.split(',').join(' :: ')}</h6>
        <h2 className="project-title">{title}</h2>
        <h5 className="project-subtitle">{subtitle}</h5>
        <div className="circle-link">
          <a href={visitLink} target="_blank" rel="noreferrer">
            <div>Visit</div>
          </a>
        </div>
      </div>
      {video?.data?.attributes?.url && inView && (
        <section className="video-absolute">
          <video className="background-video" autoPlay muted loop>
            <source
              src={video?.data?.attributes?.url}
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
            <img
              loading="lazy"
              src={fallbackBGImg?.data?.attributes?.url}
              alt={fallbackBGImg?.data?.attributes?.alt}
              title="Your browser does not support the <video> tag"
            />
          </video>
        </section>
      )}
    </section>
  );
};
