import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import PillInput from './PillInput';
import { Input } from './styled';

type inputType = 'string' | 'number' | 'array' | 'boolean';

interface GeneralFieldValues {
  placeholder: string;
  type: inputType;
  required?: boolean;
  matches?: string;
  field_allocation?: number;
}
interface ArrayFieldValues extends GeneralFieldValues {
  items: inputType;
  matches?: never;
}

interface InputProps {
  name: string;
  field: GeneralFieldValues | ArrayFieldValues;
  form: UseFormReturn<FieldValues, any>;
}

const ColumnInput: React.FC<InputProps> = ({ name, field, form }) => {
  const { type } = field;
  if (type === 'array') {
    return <PillInput formName={name} form={form} placeholder={field.placeholder} />;
  }

  const opt: any = { required: field.required };
  if (field.matches) {
    opt.pattern = new RegExp(field.matches)
  }

  return (
    <Input
      type={type === 'number' ? 'number' : 'text'}
      {...form.register(name, opt)}
      placeholder={field.placeholder}
    />
  );
};

export default ColumnInput;
