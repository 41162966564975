import styled from '@emotion/styled';

export const Title = styled.div`
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 4.5rem;
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

type MeasurerProps = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  color?: string;
};

type RelativeListProps = {
  left: string;
};
export const RelativeElement = styled.span`
  position: relative;
  left: ${({ left }: RelativeListProps) => left ?? '0px'};
`;

export const Measurer = styled.div`
  width: ${({ width }: MeasurerProps) => width ?? 0}px;
  height: ${({ height }: MeasurerProps) => height ?? 0}px;
  position: absolute;
  top: ${({ top }: MeasurerProps) => top ?? 0}px;
  left: ${({ left }: MeasurerProps) => left ?? 0}px;
  border: 1px solid ${({ color }: MeasurerProps) => color ?? '#00b7ff'};
`;

export const HollowText = styled.span<{color?: string}>`
  -webkit-text-stroke: 1.5px ${props => props?.color ?? "white"};
  color: transparent;
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const Emphasis = styled.span`
  text-decoration: solid underline white;
`;

type BoxProps = {
  maxWidth?: string;
};
export const Box = styled.div`
  display: block;
  max-width: ${({ maxWidth }: BoxProps) => maxWidth || 'auto'};
  margin: 0;
  font-weight: 400;

  @media screen and (max-width: 1440px) {
    max-width: 100%;
  }
  @media screen and (max-width: 1280px) {
    max-width: 477px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;
export const TalentBox = styled.div`
  display: block;
  max-width: ${({ maxWidth }: BoxProps) => maxWidth || 'auto'};
  margin: 0;
  font-weight: 400;

  @media screen and (max-width: 1440px) {
    max-width: 706px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 650px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const Option = styled.option`
  text-decoration: solid underline white;
  color: white;
  font-size: 4.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  background-color: var(--secondary);
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const Select = styled.select`
  font-size: 4.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  background-color: transparent;
  text-decoration: solid underline white;
  color: white;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
  transition: 0.2s;
  padding-left: 1.6px;
  outline: none;
  :focus: {
    outline: none;
  }
  :hover {
    border-radius: 1rem;
    box-shadow: -3px 10px 17px 3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -3px 10px 17px 3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -3px 10px 17px 3px rgba(0, 0, 0, 0.75);
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;
