import {
  HollowText,
  Title,
  NoWrap,
} from '../pages/Talent/components';
import { Role } from './form/styled';

export type TitleInformation = {
  position_name: string;
  description: string;
  responsibilities: string[];
  requirements: string[];
  form_schema: any;
};

export interface JointTitleProps {
  roles?: TitleInformation[];
  currentlySelected?: number;
  setChosenIndex?: any;
}

const JointTitle: React.FC<JointTitleProps> = ({
  currentlySelected,
  roles,
  setChosenIndex,
}) => {
  if (!roles)
    return (
      <Title>
        <HollowText>Join as...</HollowText>
      </Title>
    );
  const currentRole = roles?.at(currentlySelected ?? 0)!;
  const vowels = /^[aeio]/i;
  const indefiniteArticle = vowels.test(currentRole?.position_name)
    ? 'an'
    : 'a';
  const message = `Join as ${indefiniteArticle}`;
  
  return (
    <Title className="sticky-title">
      <HollowText>
        <NoWrap>{message}</NoWrap>
      </HollowText>{' '}
      {roles.map(({ position_name }, index) => (
        <Role key={index} className={`${ currentlySelected === index ? 'active' : 'disabled' }`}  onClick={() => setChosenIndex(index)}>
          {position_name}
        </Role>
      ))}
    </Title>
  );
};

export default JointTitle;
