import { Document, Page } from 'react-pdf/dist/esm/entry.vite';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import LciCapabilities from '../../assets/pdf/LCI-capabilities.pdf';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { SEO } from '../components/SEO';

const keywords = [
  'Digital marketing expertise',
  'Custom web and app development',
  'Data-driven insights',
  'Compliance and accessibility',
  'High-profile client experience',
  'Elite clientele focus',
  'Holistic digital solutions',
  'Adaptive innovation',
  'Accessibility and compliance commitment',
  'World-class copywriting',
];

export const Capabilities = () => {
  const [pdfHeight, setPDfHeight] = useState(750);
  const calculateHeight = () => {
    if (window && window?.innerHeight > 750) {
      setPDfHeight(window.innerHeight - 30);
    }
  };

  useEffect(() => {
    calculateHeight();
  }, []);

  return (
    <Layout>
      <SEO
        title="Let's Code It - Capabalities statement"
        description="As a premier San Diego-based digital marketing agency, we craft exceptional, high-performance websites and applications for our elite clientele. Our expert team of engineers, designers, and project managers employ innovative strategies and data-driven insights to propel businesses to the forefront of the digital landscape. Partner with us and experience the unparalleled service that will elevate your online presence and drive extraordinary results."
        keywords={keywords}
      />
      <StyledDocument file={LciCapabilities}>
        <StyledPage pageNumber={1} height={pdfHeight} renderMode="canvas" />
      </StyledDocument>
    </Layout>
  );
};

const StyledDocument = styled(Document)`
  padding-top: 100px;
`;

const StyledPage = styled(Page)`
  .annotationLayer {
    left: 50%;
    transform: translateX(-50%);
  }

  canvas {
    margin: 15px auto;
  }
`;
