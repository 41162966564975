import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState, useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';

export interface NavLink {
  text: string;
  href: string;
  isNewPage?: boolean;
}

export const links: Array<NavLink> = [
  {
    text: 'Projects',
    href: '#projects',
  },
  {
    text: 'Services',
    href: '#services',
  },
  {
    text: 'Talent',
    href: 'talent',
    isNewPage: true,
  },
  {
    text: 'Collaborate',
    href: 'collaborate',
    isNewPage: true,
  },
];

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Navigation({ setMobileNavOpen }: Props) {
  const [activeClass, setActiveClass] = useState('');
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.substring(1);
    setActiveClass(pathname);

    // Scroll to the anchor if the URL has a hash on load
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname, location.hash]);

  useLayoutEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        const href = anchor.getAttribute('href') || '';
        setActiveClass(href);
        e.preventDefault();
        const targetElement = document.querySelector(href);
        if (targetElement) {
          window.history.pushState({}, '', href); // Update the URL with the hash
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      import('../utils/gsap').then(({ default: gsap }) => {
        const t1 = gsap.timeline();
        t1.from('.nav-container', {
          autoAlpha: 0,
          duration: 1.5,
        });

        if (location.pathname !== '/') return;
        // gsap.to('.nav-container .nav-link', {
        //   color: 'black',
        //   duration: 0.1,
        //   scrollTrigger: {
        //     trigger: '#work',
        //     start: 'top top+=50',
        //     end: 'bottom top+=50',
        //     toggleActions: 'restart reverse restart reverse',
        //     onLeave: () => {
        //       gsap.to('.nav-container .nav-link', { color: 'white' });
        //     },
        //   },
        // });

        // gsap.to(['.nav-container a img', '.nav-container svg'], {
        //   filter: 'invert(1)',
        //   duration: 0.1,
        //   scrollTrigger: {
        //     trigger: '#work',
        //     start: 'top top+=50',
        //     end: 'bottom top+=50',
        //     toggleActions: 'restart reverse restart reverse',
        //     onLeave: () => {
        //       gsap.to(['.nav-container a img', '.nav-container svg'], { filter: 'invert(0)' });
        //     },
        //   },
        // });

        // gsap.to('.nav-link-separator', {
        //   color: '#011627',
        //   duration: 0.1,
        //   scrollTrigger: {
        //     trigger: '#work',
        //     start: 'top top+=50',
        //     end: 'bottom top+=50',
        //     toggleActions: 'restart reverse restart reverse',
        //     onLeave: () => {
        //       gsap.to('.nav-link-separator', { color: 'white' });
        //     },
        //   },
        // });

        const sections = [
          '#hero',
          '#work',
          '#services',
          '#projects',
          '#contact',
          '#talent',
          '#collaborate',
        ];

        sections.forEach((href) => {
          const target = document.querySelector(href);

          ScrollTrigger.create({
            trigger: target,
            start: 'top top+=20%',
            end: 'bottom top-=20%',
            onEnter: (self) => {
              const id = self?.trigger?.id;
              setActiveClass(`#${id}`);
              // if(target) {
              //   const bgColor = window.getComputedStyle(target).backgroundColor;
              //   const isDarkBg = bgColor === '#011627'; // Assuming blue is rgb(0, 0, 255)
              //   document.querySelectorAll('.nav-link').forEach((link) => {
              //     (link as HTMLElement).style.color = isDarkBg ? 'white' : 'black';
              //   });
              // }
            },
            onLeaveBack: (self) => {
              const id = self?.trigger?.id;
              if (id === 'contact') {
                setActiveClass('#projects');
              }
              if (id === 'projects') {
                setActiveClass('#services');
              }
              if (id === 'services') {
                setActiveClass('#work');
              }
              if (id === 'work') {
                setActiveClass('#hero');
              }
            },
          });
        });
      });
    }
  }, [location.pathname]);

  return (
    <div className="nav-container" id="navigation-container">
      <div className="navigation">
        <a href="/">
          <img id="logo" src="/LCI%20White.svg" alt="logo" />
        </a>
        <div className="nav-links">
          {links.map((link, index) => (
            <div key={index}>
              {link.isNewPage ? (
                <Link
                  className={`nav-link ${
                    activeClass === link.href ? 'active' : ''
                  }`}
                  to={location.pathname === '/' ? link.href : `/${link.href}`}
                >
                  {link.text}
                </Link>
              ) : (
                <a
                  className={`nav-link ${
                    activeClass === link.href ? 'active' : ''
                  }`}
                  href={location.pathname === '/' ? link.href : `/${link.href}`}
                >
                  {link.text}
                </a>
              )}
              {index !== links.length - 1 && (
                <span className="nav-link-separator">&nbsp;/&nbsp;</span>
              )}
            </div>
          ))}
        </div>
        <div
          className="mobile-burger"
          onClick={() => setMobileNavOpen((prev) => !prev)}
        >
          <svg
            width="35"
            height="21"
            id="mobile-svg"
            viewBox="0 0 35 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="0.5" x2="35" y2="0.5" stroke="white" />
            <line y1="10.5" x2="35" y2="10.5" stroke="white" />
            <line x1="13" y1="20.5" x2="35" y2="20.5" stroke="white" />
          </svg>
        </div>
      </div>
    </div>
  );
}
