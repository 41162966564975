import { BrowserRouter as Router } from 'react-router-dom';
import { SentryClient } from './components/containers/SentryClient';
import { AppRoutes } from './routes';

function App() {
  return (
    <SentryClient>
        <Router>
          <AppRoutes />
        </Router>
    </SentryClient>
  );
}

export default App;
