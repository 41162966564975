import { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { CategoryGroups, Service, servicesData } from './data'
import { Header } from './Header'
import { Info } from './Info'
import { Accordion } from './Accordion'
// import { Title } from './styles'
import { Tabs } from './Tabs'
import { SubHeader, Title } from '../styles'

export const OurServices: FC = () => {
  const [selectedService, setSelectedService] = useState(0)
  const { width } = useWindowDimensions()
  const isMobile = width < 700
  const groupsByCategory = servicesData.reduce((acc: CategoryGroups, curr: Service) => {
    acc[curr.category] = acc[curr.category] || [];
    acc[curr.category].push(curr);
    return acc
  }, {})

  return (
    <Article>
      <Title color={"white"}>Our Expertise and Services</Title>
      <SubHeader maxWidth='800px' color={"white"}>Explore our spectrum of web development and design solutions. At Let’s Code It, we pride ourselves in delivering high-impact custom websites that leave an impression.</SubHeader>
      <Container>
        {isMobile ? (
          <Accordion />
        ) : (
          <>
          <Tabs data={groupsByCategory} />
          </>
        )}
      </Container>
    </Article>
  )
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Article = styled.div`
  padding: 15px;
  padding-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 700px) {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
`

const SectionTitle = styled.h3`
  position: relative;
  font-size: 18px;
  font-family: Poppins;
  color: #fff;
  display: inline-block;
  width: 100%;
  max-width: 795px;
  font-weight: 200;
  padding-bottom: 40px;
  border-bottom: 1px solid black
`

const Container = styled.div`
  display: flex;
  margin-top: 40px;
  @media screen and (min-width: 700px) {
    align-items: center;
    max-width: 1100px;
    gap: 100px;
    padding: 105px 0;
  }
`
