export const servicesData = [
  {
    name: "SaaS & Product Development",
    title: "We Harness the Expertise to Develop Custom SaaS and Products That Innovate and Transform.",
    content: "At Let's Code It, we excel in developing cutting-edge SaaS and product solutions, tailored to meet the evolving needs of modern businesses. From ideation and creation of new products to enhancing existing platforms, our team specializes in delivering robust, scalable, and innovative solutions. We pride ourselves on embedding with teams to understand their unique challenges, creating custom dashboards and platforms that drive efficiency and growth.",
    services: ["Bespoke SaaS Development", "Enhancement of Existing Platforms", "Innovative Product Creation"],
    category: "Engineering"
  },
  {
    name: "Web Development",
    title: "We Possess the Expertise to Craft Custom Web That Captivate and Engage.",
    content: "At Let's Code It, we specialize in crafting bespoke web solutions, from conceptualizing and building immersive websites from scratch to enhancing and expanding existing ones. Our expertise lies in creating tailored websites for startups, businesses, and marketing purposes, ensuring each site is not only visually stunning but also highly functional and user-friendly. We focus on bringing your ideas to life, delivering web experiences that captivate your audience and elevate your online presence.",
    services: ["Custom Web Development", "Startup and Business Website Solutions", "Marketing Site Development"],
    category: "Engineering"
  },
  {
    name: "Mobile Development",
    title: "We Master the Art of Mobile App Development, Blending Advanced Technology with Exceptional User Experience.",
    content: "At Let's Code It, we specialize in crafting bespoke mobile applications that bring your ideas to life with precision and creativity. Whether it's developing a brand-new app or enhancing an existing one, our team is committed to delivering user-centric, innovative solutions. Our expertise lies in understanding your vision and transforming it into a mobile application that not only meets but exceeds market standards and user expectations.",
    services: ["Custom Mobile App Development", "Enhancement of Existing Apps", "Idea Realization", "Cross-Platform Development"],
    category: "Engineering"
  },
  {
    name: "Design",
    title: "We are masters of our design craft, thriving at the intersection of user experience and visual appeal.",
    content: "At Let's Code it, we provide premium design services that establish a distinctive online presence. Our expert designers create custom websites, mobile apps, and branding solutions that exude sophistication. Our focus on delivering high-quality UI and UX design is tailored to meet our clients' unique needs, ensuring that every design we create is visually stunning, user-friendly, and captivating.",
    services: ["Custom Web Design Services", "Mobile App Design", "Branding and Logo Design Services"],
    category: "Design"
  },
  {
    name: "Branding",
    title: "We Excel in the Art of Branding, Merging Aesthetic Innovation with Authentic Brand Narratives.",
    content: "At Let's Code It, we specialize in transforming and creating brand identities that resonate and endure. Whether it's reimagining an existing brand or crafting a new one from scratch, our team is dedicated to bringing your vision to life with innovative and impactful branding solutions. We also offer comprehensive branding and logo reviews to ensure your brand consistently stands out in the marketplace.",
    services: ["Rebranding Expertise", "Brand Creation", "Logo Design & Development", "Branding & Logo Reviews"],
    category: "Design"
  },
  {
    name: "Government Services",
    title: "Partnering with Government Agencies to Innovate and Streamline Processes.",
    content: "At Let's Code It, we offer specialized services to federal and state government agencies, leveraging our expertise in websites, apps, AI/ML, and system development. As a certified veteran-owned business, we are equipped to handle government contracts and projects, providing innovative solutions that streamline processes and enhance efficiency. Our team collaborates closely with government entities to understand their unique challenges and deliver tailored solutions that meet their specific needs.",
    services: ["Government Website Development", "Government App Development", "AI/ML Integration for Government Systems", "System Development for Government Agencies"],
    codes: ['541511','54151S'],
    category: "Government"
  }
]

export interface Service {
  name: string;
  title: string;
  content: string;
  services: string[];
  category: string;
  codes?: string[];
}

export interface CategoryGroups {
  [key: string]: Service[];
}