import React from 'react';
import { InputPill, InputPillProps, NoSelect } from './layout-components';
import './styles.css';

interface PillProps extends InputPillProps {}

/**
 * Functional Component that represents a Pill
 * @param pillDeleteColor The color of the pill when it is "deleted" (defaults to a red-like color)
 * @param style A style that's passed down to the input
 * @param key A key in case it needs to be used in lists
 * @param children The children inside the pill
 * @param onClick What to do when the pill is clicked
 * @param marked Whether the pill is marked or not (changes color)
 * @constructor
 */
const Pill: React.FC<PillProps> = ({
  pillDeleteColor = '#cc0000',
  style,
  children,
  onClick,
  marked,
}: PillProps) => {
  return (
    <InputPill
      style={{ ...style }}
      onClick={onClick}
      marked={marked}
      pillDeleteColor={pillDeleteColor}
    >
      <NoSelect>{children}</NoSelect>
    </InputPill>
  );
};
export default Pill;
