/* eslint-disable jsx-a11y/anchor-has-content */
import { Body, Title } from '../components/containers/contact-container';
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
    <section className="fullscreen-page contact" id="contact">
      <div className="contact-container" id="contact">
        <Link to="collaborate" className="contact-left"></Link>
        <Link to="/talent" className="contact-right"></Link>

        <section className="contact-inner contact-left-inner">
          <Title>Collaborate.</Title>
          <Body>
            Whether you are an early-stage startup or established brand, we can
            make great things together. Get in touch to see how we can partner.
          </Body>
        </section>
        <section className="contact-inner contact-right-inner">
          <Title isOutlined>Join.</Title>
          <Body>
            Join our team to bring ideas to life, work with challenging new
            technologies, and create lasting relationships. Get in touch to
            learn about open roles. {""}
          </Body>
        </section>
      </div>
    </section>
  );
}
