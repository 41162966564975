import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import Pill from '../../containers/Pill';
import { InputPillProps } from '../../containers/Pill/layout-components';
import { FlexInputElement, InputContainer, InputElement } from './components';
import './styles.css';

interface PillInputProps extends InputPillProps {
  placeholder?: string;
  initialPillArrayValues?: string[];
  pillStyle?: React.CSSProperties;
  form?: UseFormReturn<FieldValues, any>;
  formName?: string;
  required?: boolean;
}

/**
 *
 * Represents a text=field with pills
 * @returns
 */

const PillInput = React.forwardRef<any, PillInputProps>(
  (
    {
      initialPillArrayValues = [],
      pillStyle = {},
      placeholder = 'Add a skill...',
      form,
      formName = placeholder,
      required = true,
    },
    ref
  ) => {
    const [currentInput, setCurrentInput] = useState('');
    const [pillArray, setPillArray] = useState<string[]>([...initialPillArrayValues]);
    const [clickedPill, setClickedPill] = useState(-1);
    const { register, setValue } = form || {};

    useEffect(() => {
      register?.(formName);
    }, [register, formName]);

    const onPillClick = (index: number) => {
      if (clickedPill === -1) {
        setClickedPill(index);
        return;
      }

      const newPillArray = pillArray.filter((_, i) => i !== index);
      setValue?.(formName, newPillArray);
      setPillArray(newPillArray);
      setClickedPill(-1);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const value = (e.currentTarget as HTMLInputElement).value;
        handleAddToPill(value);
        return;
      }
      if (e.key !== 'Backspace' || currentInput) {
        if (clickedPill !== -1) setClickedPill(-1);
        setValue?.(formName, [...pillArray, currentInput])
        return;
      }
      onPillClick(pillArray.length - 1);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.endsWith(',')) {
        handleAddToPill(value);
        return;
      }
      setCurrentInput(e.target.value);
    };


    const handleAddToPill = (value: string) => {
      const formattedValue = value.trim();
      const hasComma = formattedValue.includes(',');
      const newPill = hasComma ? formattedValue.slice(0, -1).trim() : formattedValue;
      if (formattedValue.length !== 1 && pillArray.indexOf(newPill) === -1) {
        const newPills = [...pillArray, newPill];
        setValue?.(formName, newPills);
        setPillArray(newPills);
      }
      setCurrentInput('');
      return;
    }
    return (
      <InputContainer>
        {pillArray.map((pillText: string, index: number) => (
          <InputElement key={index}>
            <Pill
              onClick={() => onPillClick(index)}
              style={pillStyle}
              marked={clickedPill === index}
            >
              {pillText}
            </Pill>
          </InputElement>
        ))}

        <FlexInputElement
          ref={ref}
          type="text"
          required={pillArray.length ? !required : required}
          value={currentInput}
          onChange={handleInput}
          placeholder={pillArray.length ? "" : placeholder}
          onKeyDown={onKeyDown}
        />
      </InputContainer>
    );
  }
);

export default PillInput;
