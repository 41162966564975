import { FC, useState } from 'react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import { servicesData } from './data'
import { HollowText } from './styles'

export const Accordion = () => {
  const [selected, setSelected] = useState(0);

  const handleAccordionToggle = (index: number) => {
    setSelected(index); // Toggle the same item or switch to a new one
  };

  return (
    <Container>
      {servicesData.map((service, index) => (
        <div key={index} style={{ width: '100%'}}>
          <HollowText style={{
            letterSpacing: '1.2px',
            fontSize: '35px',  
          }} color="white" onClick={() => handleAccordionToggle(index)}>
            {service.name}
          </HollowText>
          <AnimatePresence>
            {selected === index && (
              <motion.div 
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: 'auto', opacity: 1, visibility: 'visible', width: '100%', overflow: 'visible' },
                  collapsed: { height: 0, opacity: 0, visibility: 'hidden', width: '100%', overflow: 'hidden' }
                }}
                transition={{ duration: 0.2 }}
              >
                <Content>{service.content}</Content>
                <Keywords>{service.services.map((keyword, keyIndex) => <Keyword key={keyIndex}>{keyword}</Keyword>)}</Keywords>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  overflow: hidden;
  gap: 30px;
  padding: 20px;
`

const Header = styled.h3`
  cursor: pointer;
`

const Content = styled.p`
  overflow: hidden;
`

const Keywords = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

const Keyword = styled.li`
  margin-right: 10px;
`



