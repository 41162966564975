import styled from '@emotion/styled';

export const InputContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0 0 9.48px 0;
  flex-basis: 100%;
  gap: 5px;
  height: fit-content;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 8px 0;
`;

export const FlexInputElement = styled.input`
  background: none;
  font-family: 'Poppins', sans-serif;
  border: none;
  color: white;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  flex-grow: 2;
  overflow: visible;

  :focus-visible {
    outline: none;
  }

  ::placeholder {
    color: white;
    font-weight: 300;
    font-size: 18px;
  }
`;

export const InputElement = styled.div``;

export const MaxFlex = styled.div`
  width: 100%;
`;
