import { useState, useEffect } from "react";

export function useGetClientsData() {
  const [clients, setClients] = useState([]);
  const [clientLoading, setclientLoading] = useState(true);
  const fetchClients = async () => {
    setclientLoading(true);
    try {
      const res = await fetch(
        `${import.meta.env.VITE_STRAPI_ADDRESS}/api/clients?populate=logo`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_KEY}`,
          },
        }
      );
      const projects = await res.json();
      setClients(projects.data);
    } catch (e) {}
    setclientLoading(false);
  };

  useEffect(() => {
    if (clients.length === 0) {
      fetchClients();
    }
  }, [clients]);

  return { clients, clientLoading };
}