import { useEffect, useRef, useState } from 'react';
import Vimeo from '@vimeo/player';
import styled from '@emotion/styled';
import { TiltCard } from './TiltCard';

export const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState<Vimeo>();
  const videoId = 944963871; // Replace 'YOUR_VIDEO_ID' with your actual Vimeo video ID
  const videoRef = useRef<HTMLDivElement>(null);

  const handlePlayPause = () => {
    if (!player) return;

    if (isPlaying) {
      player.pause();
      setIsPlaying(false);
    } else {
      player.play();
      setIsPlaying(true);
    }
  };

  const handleFullScreen = () => {
    if (player && videoRef.current) {
      player.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }
  };

  useEffect(() => {
    if (videoRef.current && !player) {
      const newPlayer = new Vimeo(videoRef.current, {
        id: videoId,
        width: 640,
        controls: false,
        loop: true,
      });
      setPlayer(newPlayer);
    }
  }, [videoRef, player, videoId]);

  return (
      <RelativeContainer>
        <VideoContainer >
            <Video ref={videoRef}></Video>
            <PlayContainer onClick={handlePlayPause} isPlaying={isPlaying}>
              {!isPlaying && (
                <PlayButton>Watch Our Agency Reel <RightArrow /></PlayButton>
              )}
            </PlayContainer>
          {/* <FullScreenButton onClick={handleFullScreen}>Full Screen</FullScreenButton> */}
        </VideoContainer>
        <HalfBackground />
      </RelativeContainer>
  );
};

const RelativeContainer = styled.div`
  position: relative;
`

const Video = styled.div`
  z-index: 2;
`

const HalfBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: var(--secondary);
  z-index: 0;
`

const VideoContainer = styled.div`
  position: relative;
  width: calc(100% - 50px);
  max-width: 1288px;
  margin: 0 auto;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const FullScreenButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  border: none;
  cursor: pointer;
`;

const PlayContainer = styled.div<{isPlaying?: boolean}>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 500ms ease-in;
  z-index: 1;
`
const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: 'Poppins', sans-serif;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  @media screen and (min-width: 700px) {
    font-size: 16px;
  }
`;

const RightArrow = () => (
  <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/outline/arrow-right">
      <g id="arrow-right">
        <path id="Vector" d="M12.9814 26.4351C12.1316 26.4351 11.3358 26.2328 10.6344 25.8281C8.97531 24.8704 8.05811 22.9416 8.05811 20.4058V13.1895C8.05811 10.6537 8.97531 8.72484 10.6344 7.76717C12.2935 6.80949 14.4111 6.98484 16.6232 8.25275L22.8683 11.8541C25.0669 13.1221 26.2809 14.8755 26.2809 16.7909C26.2809 18.7062 25.0669 20.4597 22.8683 21.7276L16.6232 25.329C15.3553 26.0709 14.1144 26.4351 12.9814 26.4351ZM12.9814 9.18345C12.4823 9.18345 12.0237 9.29136 11.6325 9.52066C10.6344 10.1007 10.0814 11.3955 10.0814 13.1895V20.3923C10.0814 22.1728 10.6344 23.4811 11.646 24.0611C12.6576 24.6411 14.0604 24.4658 15.6116 23.5755L21.8567 19.9741C23.4079 19.0839 24.2576 17.9509 24.2576 16.7909C24.2576 15.6309 23.4079 14.4979 21.8567 13.6076L15.6116 10.0062C14.6539 9.45322 13.7637 9.18345 12.9814 9.18345Z" fill="white"/>
      </g>
    </g>
  </svg>

)