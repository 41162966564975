import { FieldValues, UseFormReturn } from 'react-hook-form';
import ColumnInput from '../../components/form/Input';

type FormFields = {
  maxInLine: number;
  components: React.ReactNode[];
};

export const getFormValues = (
  formValues: any,
  form: UseFormReturn<FieldValues, any>
) => {
  const formFields: FormFields[] = [];
  for (const key of Object.keys(formValues)) {
    const inLine = formValues[key].field_allocation ?? 2;
    const component = (
      <ColumnInput name={key} field={formValues[key]} form={form} />
    );
    const previousInputGroup = formFields.at(-1);
    // The element is the first element in the form
    if (!previousInputGroup) {
      formFields.push({ maxInLine: inLine, components: [component] });
      continue;
    }
    if (
      // The current element asks to be alone
      inLine === 1 ||
      // The previous group is full
      previousInputGroup.maxInLine === previousInputGroup.components.length ||
      // The previous group is not full but the current element's inline is less than the previous group's maxInLine
      previousInputGroup.components.length >= inLine
    ) {
      formFields.push({ maxInLine: inLine, components: [component] });

      continue;
    }
    // The previous group is not full
    previousInputGroup.components.push(component);
  }

  return formFields;
};
