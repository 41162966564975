import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

type SiteStore = {
  shouldShowLoader: boolean;
  setShouldShowLoader: (shouldShow: boolean) => void
}

export const useSiteStore = create(
  persist<SiteStore>(
    (set, get): SiteStore => ({
      shouldShowLoader: true,
      setShouldShowLoader: (shouldShow: boolean) => set({ shouldShowLoader: shouldShow }),
    }),
    {
      name: 'site-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
)