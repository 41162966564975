import ReactGA from 'react-ga4';

export function useGoogleAnalytics() {
	const initialize = () => {
		const isDev =
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development';
      if(import.meta.env.VITE_GA_ID) {
        ReactGA.initialize(import.meta.env.VITE_GA_ID, { testMode: isDev });
      }
	};

	const recordEvent = (payload: any) => {
		ReactGA.event(payload);
	};

	const recordPageview = (page: any) => {
		ReactGA.send({ hitType: 'pageview', page });
	};

	return { initialize, recordEvent, recordPageview };
}