import { Box, Emphasis, TalentBox, RelativeElement } from '../pages/Talent/components';
import { TitleInformation } from './JointTitle';
import { RelativeListItem } from './RelativeListItem';

type FormDescriptionProps = {
  role?: TitleInformation;
  talentPage?: boolean;
};

export const FormDescription: React.FC<FormDescriptionProps> = ({ role, talentPage }) => {
  if (!role) return <></>;
  const { description, requirements, responsibilities } = role;
  const responsibilitiesRender = responsibilities.map((responsability, i) => {
    return <RelativeListItem key={i}>{responsability}</RelativeListItem>;
  });

  const requirementsRender = requirements.map((requirement, i) => {
    return <RelativeListItem key={i}>{requirement}</RelativeListItem>;
  });

  return (
    <>
      {talentPage ? (
        <>
          <TalentBox>{description}</TalentBox>
          <TalentBox
            style={{
              marginTop: '32px',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '18px',
              lineHeight: '28px',
            }}
          >
            <Emphasis>Responsibilities:</Emphasis>
            <ul>{responsibilitiesRender}</ul>
            <Emphasis>Requirements:</Emphasis>
            <ul>{requirementsRender}</ul>
          </TalentBox>
        </>
      ) : (
        <>
          <Box maxWidth="584px">{description}</Box>
          <Box
            maxWidth="584px"
            style={{
              marginTop: '16px',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '28px',
            }}
          >
            <Emphasis>Responsibilities:</Emphasis>
            <ul>{responsibilitiesRender}</ul>
            <Emphasis>Requirements:</Emphasis>
            <ul>{requirementsRender}</ul>
          </Box>
        </>
      )}
    </>
  );
};
