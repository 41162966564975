import { css } from '@emotion/react';
import styled from '@emotion/styled';

export function SubmitButton({
  isSubmitting,
  btnText,
}: {
  isSubmitting: boolean;
  btnText: string;
}) {
  const text = isSubmitting ? 'Submitting' : btnText;

  return <Button type="submit" value={text} />;
}

export const buttonBase = css`
  color: white;
  font-size: 18px;
  display: inline-grid;
  place-items: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background: transparent;

  :hover {
    background-color: var(--primary);
    color: black;
    cursor: pointer;
  }
  width: 187px;
  height: 47px;
  left: 701px;
  top: 734px;

  border: 1px solid #fffdfd;
  border-radius: 20px;
`;

export const Button: any = styled.input`
  font-size: 18px;
  line-height: 27px;
  color: white;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #fffdfd;
  border-radius: 1.25rem;
  padding: 9.6px 28.8px;
  width: fit-content;
`;
