import styled from "@emotion/styled";

export const ServiceTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-basis: 50%;
  text-align: right;
  color: white;

`

export const Title = styled.div`
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  cursor: pointer;
  color: white;
  @media screen and (max-width: 767px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

export const HollowText = styled.span<{color?: 'black' | 'white', active?: boolean}>`
  ${({ color, active }) => !active ? {
   "-webkit-text-stroke": `1.5px ${color ?? "white"}`,
    color: "transparent"
  } : {
    color: color ?? "white"
  }}
`;