import Helmet from 'react-helmet';
import WordMarkLogo from '../../assets/LCILogo/Wordmark_LCI_Black.png';
import { MetaHTMLAttributes, useEffect } from 'react';
import { useGoogleAnalytics } from '../hooks/useGA';
import { useLocation } from 'react-router-dom';

const DEFAULT_KEYWORDS = [
  'Immersive web solutions',
  'Top-tier engineers',
  'Expert designers',
  'Product strategists',
  'Innovative brand strategies',
  "Let's Code It",
  'Custom web solutions',
  'High-end brands',
  'Online presence',
  'Web design agency',
];

export type SEOProps = {
  title: string;
  description: string;
  keywords?: string[];
  meta?: React.DetailedHTMLProps<
    MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[];
  image?: string;
};

export const SEO = ({
  title,
  description,
  keywords,
  image,
  meta,
}: SEOProps) => {
  const { initialize } = useGoogleAnalytics();
  const siteKeywords = [...DEFAULT_KEYWORDS, ...(keywords ?? [])].join(',');
  const siteMeta = [
    { name: 'description', content: description },
    { name: 'keywords', content: siteKeywords },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: image ?? WordMarkLogo },
    ...(meta ?? []),
  ];

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      initialize();
    }
  }, []);

  return <Helmet title={title} meta={siteMeta} />;
};
