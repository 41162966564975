import { useState } from 'react';
import {Navigation, links as navLinks } from './Navigation';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  return (
      <nav className={mobileNavOpen ? 'mobile-nav-links-open' : ''}>
        <div
          className={`mobile-nav-links ${
            mobileNavOpen ? 'mobile-nav-links-open' : ''
          }`}
        >
          <div className="mobile-nav-links-header">
            <div onClick={() => setMobileNavOpen((prev) => !prev)}>
              <svg
                width="27"
                height="26"
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-0.5"
                  x2="35"
                  y2="-0.5"
                  transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26 25.7485)"
                  stroke="white"
                />
                <line
                  x1="0.646447"
                  y1="25.395"
                  x2="25.3952"
                  y2="0.646245"
                  stroke="white"
                />
              </svg>
            </div>
          </div>
          <div className="mobile-nav-links-content">
            {navLinks.map((link, index) => (
              <div key={index}>
                {link.isNewPage ? (
                  <Link
                    className="nav-link"
                    to={location.pathname === '/' ? link.href : `/${link.href}`}
                    onClick={() => setMobileNavOpen((prev) => !prev)}
                  >
                    {link.text}
                  </Link>
                ) : (
                  <a
                    className="nav-link"
                    href={
                      location.pathname === '/' ? link.href : `/${link.href}`
                    }
                    onClick={() => setMobileNavOpen((prev) => !prev)}
                  >
                    {link.text}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
        <Navigation setMobileNavOpen={setMobileNavOpen}></Navigation>
      </nav>
  );
};

export default Navbar;
