import styled from '@emotion/styled'
import { FC } from 'react'

type InfoProps = {
  title: string
  content: string
  keywords: string[]
  codes?: string[]
}

export const Info: FC<InfoProps> = ({ title, content, keywords, codes }) => {
  return  (
    <Container>
      <Title>{title}</Title>
      <Description>{content}</Description>
      <SkillsContainer>{keywords?.map(keyword => (
        <SkillItem key={keyword}><img src="/arrow-right.svg" />{keyword}</SkillItem>
      ))}
      {codes && (
        <ul>
          {codes.map(code => (
            <li key={code}>SIN {code}</li>
          ))}
        </ul>
      )}
      </SkillsContainer>
    </Container>
  )
}

const Container = styled.div`
  flex-basis: 50%;
  font-size: 16px;
  gap: 24px;
  display: flex;
  min-height: 450px;
  flex-direction: column;
  color: white;
`

const Title = styled.h3`
  font-size: 18px;
  margin: 0;
`

const Description = styled.p`
  margin: 0;
`
const SkillsContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 24px;
`

const SkillItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    filter: invert(1);
  }
`