import { RelativeElement } from '../pages/Talent/components';

type RelativeListItemProps = {
  children?: React.ReactNode;
  left?: string;
  relativeLeft?: string;
};

export const RelativeListItem: React.FC<RelativeListItemProps> = ({
  children,
  left = '-10px',
  relativeLeft = '-4px',
}) => {
  return (
    <li style={{ position: 'relative', left }}>
      <RelativeElement left={relativeLeft}>{children}</RelativeElement>
    </li>
  );
};
