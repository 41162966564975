import styled, { StyledComponent } from '@emotion/styled';

export type InputPillProps = {
  marked?: boolean;
  pillDeleteColor?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
};
export const InputPill: StyledComponent<InputPillProps> = styled.div`
  color: white;
  border: 1px solid
    ${({ marked, pillDeleteColor }: InputPillProps) => (marked ? pillDeleteColor : 'white')};
  border-radius: 1rem;
  width: fit-content;
  font-size: 1rem;
  background: none;
  white-space: nowrap;
  flex-wrap: wrap;
  padding: 0 12px;
  margin: 0;
`;

export const NoSelect = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;
