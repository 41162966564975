import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import Collaborate from './pages/Collaborate';
import Talent from './pages/Talent';
import { Capabilities } from './pages/Capabilities';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/collaborate" element={<Collaborate />} />
      <Route path="/talent" element={<Talent />} />
      <Route path="/capabilities" element={<Capabilities />} />
    </SentryRoutes>
  );
};
