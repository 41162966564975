import { Service, servicesData } from './data'
import { FC } from 'react';
import { ServiceTitle, Title, HollowText } from './styles';

type HeaderProps = {
  onClick: (index: number) => void
  selected: number
  services: Service[]
}

export const Header: FC<HeaderProps> = ({ onClick, selected, services }) => (
  <ServiceTitle>
    {services.map((service, index) => {
      return (
        <Title>
          <HollowText 
            onClick={() => onClick(index)} 
            active={index === selected}
            >
              {service.name}
            </HollowText>
        </Title>
      )
    })}
  </ServiceTitle>
)
