import styled from "@emotion/styled";

export const Title = styled.h2<{ color?: string}>`
  color: ${({ color }) => color ?? '#000'};
  text-align: center;
  -webkit-text-stroke-width: 1.4384615421295166;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (min-width: 700px) {
    font-size: 57.538px;
  }
`

export const SubHeader = styled.h5<{ color?: string, maxWidth?: string }>`
  color: ${({ color }) => color ?? '#000'};
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ maxWidth }) => (maxWidth && { 'max-width': maxWidth })}
  @media screen and (min-width: 700px) {
    font-size: 25.892px;
  }
`;
