import { useState } from 'react';
import Layout from '../components/Layout';
import type { ClientSchema } from '../components/OurClients';
import OurClients from '../components/OurClients';
import Contact from './Contact';
import type { ProjectSchema } from '../components/Projects';
import { Project } from '../components/Projects';

import './styles/index.css';
import { Loading } from './Loading';
import { useGetClientsData } from '../hooks/useGetClientsData';
import { useGetProjectsData } from '../hooks/useGetProjectsData';
import { PhysicsSimulation } from '../components/PhysicsSimulation';
import { SEO } from '../components/SEO';
import { OurServices } from '../components/OurServices';
import { VideoPlayer } from '../components/VideoPlayer';
import Intro from '../components/Intro';
import { useSiteStore } from '../store';

interface ProjectData {
  id: number;
  attributes: ProjectSchema;
}

interface ClientData {
  id: number;
  attributes: ClientSchema;
}

interface clientLoader {
  clients: ClientData[];
  clientLoading: boolean;
}

interface projectLoader {
  projects: ProjectData[];
  projectLoading: boolean;
}

export default function Index() {
  const { clients, clientLoading }: clientLoader = useGetClientsData();
  const { projects, projectLoading }: projectLoader = useGetProjectsData();
  const {shouldShowLoader, setShouldShowLoader} = useSiteStore((state) => state)

  if (shouldShowLoader) {
    return (
      <>
        <SEO
          title="Let's Code It - High-End Web Solutions by Top Engineers, Designers, and Product Strategists"
          description="Let's Code It is your go-to agency for immersive web solutions, delivering exceptional designs and strategies for innovative brands. Elevate your online presence with our team of top-tier experts."
        />
        <main className="container">
          <section className="fullscreen-page hero" id="hero">
            <Loading onFinishLoading={() => setShouldShowLoader(false)} />
          </section>
        </main>
      </>
    );
  }

  return (
    <>
      <SEO
        title="Let's Code It - High-End Web Solutions by Top Engineers, Designers, and Product Strategists"
        description="Let's Code It is your go-to agency for immersive web solutions, delivering exceptional designs and strategies for innovative brands. Elevate your online presence with our team of top-tier experts."
      />
      <Layout>
        <main className="container">
          <section className="fullscreen-page hero" id="hero">
            <PhysicsSimulation />
          </section>
          <section id="work">
            {clientLoading ? (
              <p>loading...</p>
            ) : (
              <>
                <OurClients clients={clients.map((v) => v.attributes)} />
                <Intro />
                <VideoPlayer />
              </>
            )}
          </section>
          <section className="services" id="services">
            <OurServices />
          </section>
          <section className="projects" id="projects">
            {projectLoading ? (
              <p>loading...</p>
            ) : (
              (projects || [])
                .sort((a, b) => b.attributes.order - a.attributes.order)
                .map((project) => (
                  <Project key={project.id} data={project.attributes} />
                ))
            )}
          </section>
          <Contact />
        </main>
      </Layout>
    </>
  );
}
