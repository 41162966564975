import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormLayout from '../components/form/FormLayout';
import Select, { OptionProp } from '../components/form/Select';
import { Input, InputGroup } from '../components/form/styled';
import './styles/index.css';
import { Box, Title } from './Talent/components';
import ReactHelmet from 'react-helmet';
import { SEO } from '../components/SEO';

interface Timeframe {
  value: string;
  label: string;
}

const timeFrames: Timeframe[] = [
  { value: '', label: 'Timeframe' },
  { value: 'Starts in 1 week', label: 'Starts in 1 week' },
  { value: 'Starts in 2 weeks', label: 'Starts in 2 weeks' },
  { value: 'Starts in one month', label: 'Starts in one month' },
  { value: 'No timeframe', label: 'No timeframe' },
];

export default function Collaborate() {
  const form = useForm();
  const { register, setValue, watch } = form;

  const budget = watch('budget');
  const timeFrame = watch('timeframe');

  useEffect(() => {
    register('budget');
    register('timeframe');
  }, [register]);

  const handleTimeframeChange = (value: string) => {
    const timeframe = timeFrames.find((f) => f.label === value);
    setValue('timeframe', timeframe?.value);
  };

  const options: OptionProp[] = timeFrames.map((timeframe) => ({
    value: timeframe.value,
    label: `${timeframe.label}`,
  }));

  const handleChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    let formattedValue = '';

    if (value !== '') {
      const intValue = parseInt(value.replace(/[^0-9]/g, ''));
      if (!isNaN(intValue)) {
        formattedValue = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
          .format(intValue)
          .replace(/(\.00)$/, '');
      }
    }

    setValue('budget', formattedValue);
  };

  return (
    <>
      <SEO
        title="Let's Code It - Collaborate with us!"
        description="Whether you are an early-stage startup or established brand, we can make great things together. Get in touch to see how we can partner."
      />
      <FormLayout
        decorator
        flex={{ description: 47.5, form: 52 }}
        style={{
          margin:
            'calc(max(var(--navbar-height), 190px) - min(var(--navbar-height), 190px)) auto 135px auto',
        }}
        title={
          <Title
            style={{
              lineHeight: '30px',
            }}
          >
            Collaborate
          </Title>
        }
        desc={
          <Box
            maxWidth="477px"
            style={{
              margin: '40px 0 0 0',
            }}
          >
            Whether you are an early-stage startup or established brand, we can
            make great things together. Get in touch to see how we can partner.
          </Box>
        }
        form={form}
        formChannel={'#work-with-us-contact'}
        btnText={'Submit Project'}
      >
        <Box
          style={{
            marginTop: '8px',
            display: 'flex',
            gap: '42px',
            flexWrap: 'wrap',
          }}
          maxWidth="676px"
        >
          <InputGroup>
            <Input
              type="text"
              {...form.register('user_name', { required: true })}
              placeholder="Your Name"
            />
            <Input
              type="email"
              {...form.register('email', { required: true })}
              placeholder="Email"
            />
          </InputGroup>
          <InputGroup>
            <Input
              type="text"
              placeholder="Budget"
              value={budget}
              required
              onChange={handleChange}
            />
            <Select
              options={options}
              value={timeFrame}
              onChange={handleTimeframeChange}
              required={true}
            />
          </InputGroup>
          <Input
            type="text"
            {...form.register('project_overview', { required: true })}
            placeholder="Project Overview"
          />
        </Box>
      </FormLayout>
    </>
  );
}
