import React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { ContactContainer, NewInquiryBtn } from '../containers/contact-container';
import { FormButtonGroup, StyledRemixForm } from './styled';
import Layout from '../Layout';
import { SubmitButton } from './SubmitButton';
import { converObjectToString } from '../../utils/helpers';

interface Props {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  desc: string | React.ReactNode;
  form: UseFormReturn<FieldValues, any>;
  formChannel: string;
  btnText: string;
  decorator?: boolean;
  onSubmit?: (data: FormData) => Promise<void>;
  showSubmit?: boolean;
  customReset?: () => void;
  style?: React.CSSProperties;
  flex?: {
    title?: number;
    description?: number;
    form?: number;
  };
}

function FormLayout({
  children,
  form,
  title,
  desc,
  formChannel,
  customReset,
  btnText,
  showSubmit = true,
  decorator,
  style,
  flex,
}: Props) {
  const { handleSubmit, reset, formState } = form;


  const onSubmit = async (data: any) => {
    const slackData = {
      text: converObjectToString(data),
      username: 'Work With Us Contact',
      icon_emoji: ':ghost:',
      channel: formChannel,
    };

    const res = await fetch(import.meta.env.VITE_SLACK_WEBHOOK_URL, {
      method: 'POST',
      body: JSON.stringify(slackData),
    });

    if (!res.ok) {
      try {
        const {
          errors: { general },
        } = await res.json();
        for (const error in general) {
          form.setError(error, {
            type: 'validate',
            message: general[error],
          });
        }
      } catch {
        // no op
      }
      throw new Error(res.statusText);
    }
    reset();
    customReset?.();
  };

  return (
    <Layout>
      <ContactContainer style={style} decorator={decorator} title={title} desc={desc} flex={flex} isSubmitSuccessful={formState.isSubmitSuccessful}>
        {formState.isSubmitSuccessful ? (
          <div>
            <div>The form has been sent!</div>
            <NewInquiryBtn
              onClick={() => {
                reset();
              }}
            >
              Send New Inquiry
            </NewInquiryBtn>
          </div>
        ) : (
          <StyledRemixForm onSubmit={handleSubmit(onSubmit)}>
            {Object.values(formState.errors)
              .filter((error) => error?.message)
              .map((error: any) => (
                <div key={error!.message as string}>{error?.message}</div>
              ))}
            {children}
            <FormButtonGroup>
              {showSubmit && (
                <SubmitButton isSubmitting={formState.isSubmitting} btnText={btnText} />
              )}
            </FormButtonGroup>
          </StyledRemixForm>
        )}
      </ContactContainer>
    </Layout>
  );
}

export default FormLayout;
