import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { injectGlobal, css } from '@emotion/css'

export type ClientSchema = {
  createdAt: string;
  publishedAt: string;
  name: string;
  updatedAt: string;
  logo: any;
};

export default function OurClients({
  clients,
}: {
  clients: Array<ClientSchema>;
}) {
  return (
      <div className={containerStyles}>
        <Marquee>
          <MarqueeGroup>
            {clients.map((client, k) => (
              <Figure key={k}>
                <img
                  loading="lazy"
                  src={client.logo.data.attributes?.url}
                  alt={client.name}
                />
              </Figure>
            ))}
          </MarqueeGroup>
          <MarqueeGroup aria-hidden="true">
            {clients.map((client, k) => (
              <Figure key={k}>
                <img
                  loading="lazy"
                  src={client.logo.data.attributes?.url}
                  alt={client.name}
                />
              </Figure>
            ))}
          </MarqueeGroup>
        </Marquee>
      </div>
  );
}

injectGlobal`
  :root {
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 30s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
  }
`

const Figure = styled.figure`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 250px;
`;

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
  background-color: white;
  padding: 15px 0;
  border: 3px solid black;
`

const marquee = keyframes`
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
`

const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
`
const MarqueeGroup = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: ${marquee} var(--duration) linear infinite;
`