import { useCallback } from "react";
import "./styles/Loading.css";
import { motion, useAnimation } from "framer-motion";
import { AnimatedLCILogo } from '../components/AnimatedLCILogo';

type LoadingProps = {
    onFinishLoading?: () => void;
}

export const Loading = ({ onFinishLoading = () => {} }: LoadingProps) => {
    const controls = useAnimation();

    const onAnimationComplete = useCallback(async () => {
        await controls.start({ opacity: 0 });
        onFinishLoading();
    }, [controls, onFinishLoading]);

    return (
        <motion.div 
            initial={{ opacity: 1 }} 
            animate={controls} 
            className="loading-container"
            style={{ maxWidth: '100%', boxSizing: 'border-box', padding: '0 30px', backgroundColor: '#011627' }}>
            <AnimatedLCILogo onAnimationComplete={onAnimationComplete} />
        </motion.div>
    );
}