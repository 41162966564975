import type { FC } from 'react';
import styled from '@emotion/styled';

type FlexProps = {
  title?: number;
  description?: number;
  form?: number;
};

type ContactContainerProps = {
  children: any;
  title: string | React.ReactNode;
  desc: string | React.ReactNode;
  decorator?: boolean;
  style?: React.CSSProperties;
  flex?: FlexProps;
  isSubmitSuccessful?: boolean;
};

export const TalentContactContainer: FC<ContactContainerProps> = ({
  children,
  title,
  desc,
  decorator = false,
  style,
}) => {
  return (
    <Container>
      {decorator && <Decorator />}
      <Wrapper style={style}>
        <TitleContainer>{title}</TitleContainer>
        <RightColumnContainer>
            {desc}
            {children}
        </RightColumnContainer>
      </Wrapper>
    </Container>
  );
};

export const Container = styled.div`
  padding: var(--navbar-height) 3.125rem 1px 4.5rem;
  box-sizing: border-box;
  height: auto;
  color: white;
  @media screen and (max-width: 1024px) {
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const Decorator = styled.div`
  margin: calc(max(var(--navbar-height), 239px) - min(var(--navbar-height), 239px)) auto 135px auto;
  height: 0;
  width: 100%;
  border: 1px solid white;
  @media screen and (max-width: 1024px) {
    margin: calc(max(var(--navbar-height), 40px) - min(var(--navbar-height), 40px)) auto 59px auto
  }
`;

export const Wrapper = styled.div`
  max-width: 1360px;
  margin: auto;
  display: flex;
  gap: 132px;
  .form-margin {
    margin-top: 56px;
  }
  @media screen and (max-width: 1024px) {
    flex: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
`;

export const TitleContainer = styled.div`
  .sticky-title {
    position: sticky;
    top: 20%;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .sticky-title {
        text-align: center;
    }
  }
`;

export const RightColumnContainer = styled.div`
  padding: 35px 0 0 0;

  @media screen and (max-width: 1440px) {
    padding-right: 152px;
  }
  @media screen and (max-width: 1280px) {
    padding-right: 120px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 0;
  }
`;
