import React, { useEffect, useLayoutEffect } from 'react';
import script from '../utils/script.js?raw';
import styled from '@emotion/styled';

type PhysicsSimulationProps = {};

export const PhysicsSimulation: React.FC<PhysicsSimulationProps> = () => {
  useLayoutEffect(() => {
    // If window is smaller than 750px, se show a static svg instead of the hero animation.
    const magic = document.querySelector('#magic');
    if (!magic) return;

    if (window.innerWidth < 750) {
      const img = document.createElement('img');
      img.src = 'heroText.svg';
      img.alt = 'Hero Text';
      magic.appendChild(img);
      return;
    }

    const vertexScript = document.createElement('script');
    const fragmentScript = document.createElement('script');

    vertexScript.type = 'x-shader/x-vertex';
    vertexScript.id = 'vertexshader';
    vertexScript.textContent = `
      attribute float size;
      attribute vec3 customColor;
      varying vec3 vColor;

      void main() {
        vColor = customColor;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_PointSize = size * ( 300.0 / -mvPosition.z );
        gl_Position = projectionMatrix * mvPosition;

      }
    `;

    fragmentScript.type = 'x-shader/x-fragment';
    fragmentScript.id = 'fragmentshader';
    fragmentScript.textContent = `
      uniform vec3 color;
      uniform sampler2D pointTexture;

      varying vec3 vColor;

      void main() {
        gl_FragColor = vec4( color * vColor, 1.0 );
        gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );

      }
    `;

    document.querySelector('head')!.appendChild(vertexScript);
    document.querySelector('head')!.appendChild(fragmentScript);

    const execScript = document.createElement('script');
    execScript.textContent = script;

    document.querySelector('head')!.appendChild(execScript);
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      import('../utils/gsap').then(({ default: gsap }) => {
        const t1 = gsap.timeline();

        t1.from('.plus-symbols-container img', {
          y: -100,
          delay: 0.2,
          autoAlpha: 0,
          stagger: 0.2,
        });
        t1.to(
          '#magic',
          {
            opacity: 1,
            autoAlpha: 1,
            y: 0,
            duration: 0.8,
          },
          '-=0.2'
        );
        t1.to(
          '.separator',
          {
            scaleX: 1,
          },
          '-=0.6'
        );
        t1.from('.hero-subtitle', {
          autoAlpha: 0,
        });
      });
    }
  }, []);

  return (
    <>
      <div id="magic"></div>
      <section className="plus-symbols-container">
        <Figure>
          <img src="/plus.svg" alt="plus symbol" />
        </Figure>
        <Figure>
          <img src="/plus.svg" alt="plus symbol" />
        </Figure>
      </section>
      {/* <section className="hero-content">
        <div className="hero-subtitle">
          <h1>
            We are a group of top tier engineers, designers and product
            strategists specializing in immersive web solutions for innovative
            brands.
          </h1>
        </div>
        <hr className="separator" />
      </section> */}
    </>
  );
};

const Figure = styled.figure`
  margin: 0;
`;
